import React from "react";

export const PrivatePolicyPage = () => {
  return (
    <div className="private-policy-page p-20">
      <p>
        <strong>
          <span>Polityka prywatności [B2C]</span>
        </strong>
        &nbsp;
        <br />
        <br />
      </p>
      <p>Polityka prywatności aplikacji Cardio Bike&nbsp;</p>
      <br />
      <p>1. Wprowadzenie&nbsp;</p>
      <p>1.1. O aplikacji&nbsp;</p>
      <p>1.2. Dostawca Aplikacji&nbsp;</p>
      <p>1.3. Polityka Prywatności&nbsp;</p>
      <p>2. Administrator danych&nbsp;</p>
      <p>2.1. Administrator danych&nbsp;</p>
      <p>2.2. Inspektor ochrony danych&nbsp;</p>
      <p>3. Zbieranie informacji&nbsp;</p>
      <p>3.1. Og&oacute;lne&nbsp;</p>
      <p>3.2. Dane dodatkowe&nbsp;</p>
      <p>4. Cele przetwarzania danych&nbsp;</p>
      <p>4.1. Korzystanie produkt&oacute;w&nbsp;</p>
      <p>4.2. Potrzeby biznesowe</p>
      <p>4.3. Zgodność z przepisami&nbsp;</p>
      <p>5. Ujawnianie danych osobowych&nbsp;</p>
      <p>5.1. Ujawnianie&nbsp;</p>
      <p>5.2. Tryb użytkownika&nbsp;</p>
      <p>5.3. Sprzedawanie danych&nbsp;</p>
      <p>6. Okres przechowywania danych&nbsp;</p>
      <p>6.1. Okres przechowywania.&nbsp;</p>
      <p>6.2. Likwidacja konta&nbsp;</p>
      <p>7. Prawa użytkownika.&nbsp;</p>
      <p>7.1. Wycofanie zgody&nbsp;</p>
      <p>7.2. Prawo dostępu&nbsp;</p>
      <p>7.3. Prawo do sprostowania&nbsp;</p>
      <p>7.4. Prawo do usunięcia&nbsp;</p>
      <p>7.5. Prawo do ograniczenia przetwarzania&nbsp;</p>
      <p>7.6. Prawo do przeniesienia danych&nbsp;</p>
      <p>7.7. Prawo do wniesienia sprzeciwu&nbsp;</p>
      <p>7.8. Prawo do składania skarg&nbsp;</p>
      <p>8. R&oacute;żne&nbsp;</p>
      <p>8.1. Podstawy prawne&nbsp;</p>
      <p>8.2. Środki bezpieczeństwa&nbsp;</p>
      <p>
        <br />
      </p>
      <p>1. Wprowadzenie&nbsp;</p>
      <p>
        &nbsp; &nbsp; &nbsp;1.1.O aplikacji. Cardio Bike (dalej: Aplikacja lub
        Cardio Bike) to platforma obejmująca system informatyczny działający na
        urządzeniach mobilnych, umożlwiający łączenie z kompatybilną, zewnętrzną
        infrastrukturą techniczną, kt&oacute;rego gł&oacute;wnym zadaniem jest
        wspomaganie pacjent&oacute;w w procesach terapeutycznych, zapewnienie
        możliwości pomiaru, monitorowania oraz analizy stanu zdrowia oraz
        umożliwienie podejmowania prozdrowotnej aktywności fizycznej w warunkach
        kontrolowanych przez działanie Aplikacji. Rozwiązanie znajduje
        zastosowanie w pierwszej kolejności w stosunku do os&oacute;b o niskim
        poziomie dysfunkcji zdrowotnych, nie mających przeciwskazań do
        uprawiania sportu oraz kwalifikujących się do terapii ruchowej, ale
        także dla os&oacute;b podlegającym zabiegom odchudzającym,
        rehabilitacji, a kończąc na użytkowaniu czysto rekreacyjnym.&nbsp;
      </p>
      <p>
        <span>&nbsp; &nbsp;&nbsp;</span>
        1.2.Dostawca Aplikacji. Dostawcą Aplikacji oraz platformy internetowej
        CARDIOVELO (dalej: Platforma) jest CLOUD Sp. z o. o.
      </p>
      <p>
        &nbsp; &nbsp; 1.3.Polityka Prywatności.Niniejsza polityka prywatności
        (&bdquo;Polityka prywatności&rdquo;) obejmuje informacje o Aplikacji i
        Dostawcy oraz o charakterze, zakresie i celach przetwarzania Twoich
        danych osobowych.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; 1.4.Definicje. Pojęcia i terminy pisane przy użyciu
        wielkiej litery, a niezdefiniowane w niniejszej Polityce Prywatności
        noszą znaczenie przypisane im w Regulaminie.
      </p>
      <p>2. Administrator danych.&nbsp;</p>
      <p>
        &nbsp; &nbsp; 2.1.Administrator danych. Administratorem danych jest
        zbieranych podczas używania Aplikacji lub Platformy jest Dostawca. Z
        administratorem możesz skontaktować się e-mailowo pod adresem
        biuro@cardiovelo.pl.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; 2.2.Inspektor ochrony danych.Z naszym inspektorem ochrony
        danych można się kontaktować e-mailowo, pod adresem biuro@cardiovelo.pl.
        Jeżeli masz pytania dotyczące przetwarzania Twoich danych osobowych,
        skontaktuj się z nim.
      </p>
      <p>3. Zbieranie informacji&nbsp;</p>
      <p>
        &nbsp; &nbsp; 3.1.Rodzaje informacji. Zbieramy informacje przekazywane
        nam przez użytkownika lub na dostęp do kt&oacute;rych użytkownik nam
        zezwolił, takie jak:&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; 3.1.1.Informacje identyfikujące&ndash; m.in.
        imię i nazwisko, data urodzenia, adres e-mail, płeć, zdjęcie profilowe,
        identyfikatory medi&oacute;w społecznościowych i informacje przekazane
        nam za pośrednictwem Twojego konta na Facebooku lub Google. Korzystamy z
        nich do weryfikacji Twojej tożsamości.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.2.Dane kontaktowe&ndash; m.in.
        Tw&oacute;j numer telefonu, adres, adres e-mail, identyfikator w
        serwisie Messenger, identyfikator w mediach społecznościowych, inne
        kanały komunikacyjne, z kt&oacute;rych korzystasz do kontaktu z nami w
        celu uzyskania dalszych informacji. Korzystamy z nich w celu
        skontaktowania się z Tobą z r&oacute;żnych powod&oacute;w zależnie od
        celu.
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.3.Dane o lokalizacji&ndash; m.in.
        Twoje wybrane miejsce zamieszkania, aktualna lokalizacja logowania
        (adres IP) lub lokalizacja GPS (jeżeli chcesz nam ją ujawnić, na
        przykład poprzez ustawienia urządzenia mobilnego) lub inne związane z
        telefonem dane o lokalizacji (np. poprzez WiFi lub Bluetooth) albo z
        konkretnej odwiedzonej przez Ciebie witryny Platformy lub Aplikacji,
        mogące zawierać wskaz&oacute;wki na temat Twojego miejsca pobytu.
        Korzystamy z nich w celu prowadzenia naszych produkt&oacute;w i
        dopasowania Twoich doświadczeń z produktem do Twojego miejsca
        pobytu.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.1.4.Informacje z medi&oacute;w
        społecznościowych&ndash; m.in. informacje uzyskane poprzez kontakty z
        nami w r&oacute;żnych kanałach medi&oacute;w społecznościowych takich
        jak Facebook, Instagram, Google itd., w tym publicznie dostępne
        informacje z medi&oacute;w społecznościowych, takie jak Twoje
        identyfikatory w mediach społecznościowych, interakcje w mediach
        społecznościowych i publiczne dostępne wpisy, Twoje
        &bdquo;polubienia&rdquo; i inne reakcje, Twoje relacje w mediach
        społecznościowych, Twoje zdjęcia dostępne publicznie lub te,
        kt&oacute;re nam wysyłasz przez wymienienie nas, a także śledzenie
        naszych wpis&oacute;w w mediach społecznościowych przy użyciu
        identyfikator&oacute;w lub hashtag&oacute;w. Informacje te uzyskujemy z
        sieci medi&oacute;w społecznościowych (np. Facebook, Snapchat, Instagram
        itd.) bezpośrednio lub pośrednio poprzez niezależne podmioty, z
        kt&oacute;rymi jesteśmy związani umowami.
      </p>
      <p>
        <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
        3.1.5.Informacje o urządzeniach&ndash; m.in. informacje o Twoich
        urządzeniach lub przeglądarkach, kt&oacute;re wskazują na Twoje
        zachowania w Internecie lub spos&oacute;b korzystania z urządzeń.
        Informacje o Twoich urządzeniach są zbierane przez nasze aplikacje, a
        informacje o Twoich przeglądarkach są zbierane przez nasze pliki cookie,
        tagi i piksele. Jest to często wymagane ze względ&oacute;w
        bezpieczeństwa sieciowego. Obejmuje to m.in. adres IP, datę i godzinę
        wejścia na witrynę, czas pobytu na witrynie, ilość przesłanych danych,
        odsyłający adres URL (w przypadku przejścia na naszą witrynę z innej
        witryny lub za pośrednictwem reklamy), strony odwiedzone na naszej
        witrynie, rodzaj Twojej przeglądarki (język i wersja oprogramowania),
        dodatki do przeglądarki, identyfikator i cechy urządzenia, typ i wersja
        urządzenia, system operacyjny.&nbsp;
      </p>
      <p>
        <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
        3.1.6.Informacje o aktywności&ndash; m.in. dane o sprawności fizycznej
        (na przykład godzina rozpoczęcia i zakończenia użytkowania Cardio Bike
        (godzina rozpoczęcia treningu, rodzaj aktywności), dane z
        czujnik&oacute;w (m.in. docelowy dystans, czas trwania, tempo, kalorie,
        rytm serca, prędkość), informacje żywieniowe (np. czas, kalorie i inne
        dane (np. trasy i zdjęcia, dane o lokalizacji) dotyczące Twojej
        Aplikacji. Korzystamy z nich do prowadzenia naszych produkt&oacute;w,
        zapewniania wsparcia w osiąganiu lepszych wynik&oacute;w i ulepszenia
        Twoich doświadczeń użytkownika oraz określenia sugestii dotyczących
        używania Aplikacji dla Ciebie w oparciu o Twoje schematy aktywności
        fizycznej.&nbsp;
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
        3.1.7.Informacje o preferencjach&ndash; m.in. preferowany język, miejsce
        logowania, jednostki (dystans, waga, temperatura), cele osobiste i
        motywacja (np. roczny cel w przejazdach, wadze), informacje o planie
        treningowym (np. data rozpoczęcia, plan treningowy). Korzystamy z nich,
        aby ułatwić korzystanie z Cardio Bike&nbsp;
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp;&nbsp;</span>
        3.2.Dane dodatkowe. Jeżeli zarejestrujesz się w Cardio Bike za
        pośrednictwem loginu medi&oacute;w społecznościowych, otrzymamy
        następującą informacje:&nbsp;
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
        <span> &nbsp;</span>
        3.2.1.Facebook Inc. (1601 South California Avenue, Palo Alto, CA 94304,
        USA,&bdquo;Facebook&rdquo;): Imię i nazwisko, adres e-mail, płeć, data
        urodzenia, zdjęcie profilowe;
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
        <span> &nbsp;</span>
        3.2.2.Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043,
        USA,&bdquo;Google&rdquo;): Imię i nazwisko, adres e-mail, płeć, data
        urodzenia, zdjęcie profilowe.&nbsp;
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp;&nbsp;</span>
        <span> &nbsp;</span>
        3.3.Pliki cookie i podobne technologie.Aplikacja i Platforma internetowa
        korzysta z plik&oacute;w cookie &ndash; małych plik&oacute;w tekstowych
        zapisywanych i/lub przechowywanych na Twoim komputerze, urządzeniu
        mobilnym przez przeglądarkę. Podstawą przetwarzania danych za
        pośrednictwem plik&oacute;w cookie są nasze uzasadnione interesy&nbsp;
      </p>
      <p>4. Cele przetwarzania danych&nbsp;</p>
      <p>
        <span id="isPasted">&nbsp; &nbsp;&nbsp;</span>
        <span>&nbsp;</span>
        4.1.Korzystanie produkt&oacute;w. Przetwarzamy Twoje dane, aby zapewnić
        Ci jak największy komfort korzystania z oferowanych przez nas
        produkt&oacute;w.&nbsp;
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp;&nbsp;</span>
        <span> &nbsp;</span>
        4.2.Potrzeby biznesowe.Przetwarzamy Twoje dane w celu zaspokajania
        naszych uzasadnionych potrzeb biznesowych.
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
        <span> &nbsp;</span>
        <span> &nbsp;</span>
        4.2.1.Jakość Działania. Przetwarzamy dane w celu monitorowania,
        analizowania i podnoszenia komfortu korzystania z Aplikacji, a także
        ochrony lub zabezpieczenia integralności Aplikacji, jakości jej
        działania i funkcjonowania. Na przykład analizujemy zachowanie
        użytkownika i spos&oacute;b użytkowania przez niego Aplikacji lub
        Platformy.&nbsp;
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
        <span> &nbsp;</span>
        <span> &nbsp;</span>
        4.2.2.Badanie i rozw&oacute;j.Przetwarzamy dane, w tym informacje
        zwrotne użytkownik&oacute;w, w celu badania dla celu przyszłych badań
        naszych produkt&oacute;w, aby m&oacute;c oferować Tobie i innym lepsze,
        bardziej intuicyjne i spersonalizowane doświadczenie, a także spowodować
        wzrost liczby użytkownik&oacute;w i zaangażowanie w nasze
        produkty.&nbsp;
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
        <span>&nbsp;</span>
        <span></span>
        4.2.3.Og&oacute;lne materiały marketingowe.Przetwarzamy dane w celu
        dostarczania Tobie (dopasowanych indywidualnie) materiał&oacute;w
        marketingowych na temat produkt&oacute;w związanych z Cardio Bike.&nbsp;
      </p>
      <p>
        <span id="isPasted">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        &nbsp; &nbsp; &nbsp;4.2.3.1.Rezygnacja z marketingu bezpośredniego.
        Jeżeli użytkownik posiada konto w Cardio Bike (Użytkownik
        Zarejestrowany), może on zrezygnować z otrzymywania korespondencji
        marketingowej Dostawcy poprzez modyfikację preferencji użytkownika
        dostępnych na Platformie. Użytkownik może r&oacute;wnież zrezygnować
        poprzez wprowadzenie zmian do subskrypcji wiadomości elektronicznych lub
        wiadomości SMS klikając na link &bdquo;wypisz się&rdquo; lub postępując
        zgodnie z instrukcjami zawartymi w wiadomości.
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 4.3.Zgodność z przepisami. Przetwarzamy Twoje dane
        w celu zapewnienia działania Aplikacji i Platformy w spos&oacute;b
        zgodny z prawem&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;4.3.1.Zgodność z
        przepisami.Przetwarzamy Twoje dane w celu spełnienia naszych zobowiązań
        i zapewnienia zgodności z wszystkimi stosownymi przepisami i regulacjami
        prawnymi.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.3.2.Egzekwowanie
        przepis&oacute;w.Przetwarzamy Twoje dane, jeżeli naszym zdaniem jest to
        niezbędne dla cel&oacute;w bezpieczeństwa lub do przeprowadzenia
        dochodzenia w sprawie podejrzanego oszustwa lub innego naruszenia
        naszego Regulaminu lub niniejszej Polityki prywatności.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 4.4.Pozostałe cele. Dostawca może ponadto
        wykorzystywać dane osobowe użytkownik&oacute;w w inny spos&oacute;b, o
        czym będzie każdorazowo stosownie zawiadamiać użytkownik&oacute;w w
        chwili pozyskania danych i &ndash; tam gdzie to konieczne &ndash; prosić
        użytkownika o wyrażenie zgody.&nbsp;
      </p>
      <p>5. Ujawnianie danych osobowych.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; 5.1.Ujawniamy dane podmiotom trzecim:&nbsp;</p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;5.1.1.W razie potrzeby
        dla realizacji cel&oacute;w określonych w niniejszej Polityce
        Prywatności oraz regulaminie,&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;5.1.2.na żądanie władz
        krajowych,&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;5.1.3.zgodnie z decyzją
        sądu,&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;5.1.4.jeżeli wymaga tego
        prawo,&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;5.1.5.w razie potrzeby
        do prowadzenia dochodzenia i obrony przed roszczeniami i zarzutami
        podmiot&oacute;w trzecich,&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;5.1.6.w celu korzystania
        lub ochrony praw i bezpieczeństwa Cardio Bike, naszych członk&oacute;w,
        personelu lub&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;5.1.7.jeżeli
        udzieliłeś/udzieliłaś na to przedtem (wyraźnej) zgody.&nbsp;
      </p>
      <p>
        Jeżeli uznamy to za właściwe, podejmiemy pr&oacute;bę powiadomienia
        Ciebie o prawnie uzasadnionych żądaniach udostępnienia Twoich danych,
        chyba że jest to zabronione na mocy prawa lub decyzji sądowej lub jeżeli
        żądanie jest pilne. Możemy zakwestionować takie żądania, jeżeli uznamy,
        że są one zbyt szerokie, nieprecyzyjne lub bezprawne. Specjalne
        kategorie danych osobowych, takie jak dane dotyczące tętna czy inne dane
        zdrowotne, nigdy nie będą ujawniane reklamodawcom ani podobnym
        podmiotom.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 5.2.Tryb użytkownika. Cardio Bike rozr&oacute;żnia
        2 tryby użytkownika &ndash; Gość oraz Użytkownik Zarejestrowany.
        Zasadniczo nie przechowujemy danych identyfikujących użytkownik&oacute;w
        w trybie Gość po zakończeniu używania Aplikacji. Pozyskane dane
        lokalizacyjne lub informacje o aktywności przechowywane będą w wersji
        zanonimizowanej&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 5.3.Sprzedawanie danych.Nie sprzedajemy żadnych
        Twoich danych osobowych stronom trzecim.&nbsp;
      </p>
      <p>6. Okres przechowywania danych&nbsp;</p>
      <p>
        &nbsp; &nbsp; &nbsp; 6.1.Okres przechowywania.Musimy przechowywać Twoje
        dane tak długo, jak masz u nas konto. Jeżeli jesteś użytkownikiem z UE i
        zaprzestaniesz korzystania z naszych usług, nie żądając usunięcia swoich
        danych, będziemy je przechowywać przez 25 miesięcy od Twojego ostatniego
        kontaktu z Cardio Bike. Poza tym Dane przechowujemy tylko wtedy, gdy
        wymaga tego prawo (ze względu na gwarancję, okresy przedawnienia lub
        przechowywania) lub ze względu na inne wymogi.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 6.2.Likwidacja konta.Jeżeli postanowisz zlikwidować
        swoje konto, wszystkie Twoje dane będące w naszym posiadaniu zostaną
        usunięte, z następującymi wyjątkami:&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;6.2.1.Dane ujawnione publicznie
        przez Ciebie (np. trasy, uwagi na temat aktywności sportowej innych
        zarejestrowanych użytkownik&oacute;w) zostaną przedstawione w
        zanonimizowanej postaci, tj. będzie wyraźnie zaznaczone, że te dane
        zostały dostarczone przez usuniętego użytkownika.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.2.2.Dane potrzebne do
        spełnienia przez nas obowiązk&oacute;w umownych lub dochowania
        ustawowych okres&oacute;w przetrzymywania nie zostaną usunięte, a
        jedynie w niezbędnym zakresie zminimalizowane.&nbsp;
      </p>
      <p>
        Żądanie usunięcia nie dotyczy danych, kt&oacute;rych przechowywania
        wymaga prawo, na przykład do cel&oacute;w rachunkowych.
      </p>
      <p>7. Prawa użytkownika.</p>
      <p>
        &nbsp; &nbsp; &nbsp; 7.1.Wycofanie zgody.W każdej chwili możesz wycofać
        swoją zgodę &ndash; w przypadkach wymagających udzielenia zgody na
        przetwarzanie &ndash; na przyszłe przetwarzanie danych. Nie wpływa to
        jednak na zgodność z prawem przetwarzania danych na podstawie zgody
        udzielonej przed jej wycofaniem. W pewnych przypadkach możemy
        kontynuować przetwarzanie Twoich danych po wycofaniu zgody, jeżeli
        posiadamy do tego inną podstawę prawną lub jeżeli wycofanie przez Ciebie
        zgody było ograniczone do określonych czynności przetwarzania.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 7.2.Prawo dostępu.Masz prawo do uzyskania (i)
        potwierdzenia, czy przetwarzamy Twoje dane, a jeżeli tak, (ii) bardziej
        szczeg&oacute;łowych informacji o tych danych. Bardziej
        szczeg&oacute;łowe dane obejmują m.in. cele przetwarzania, kategorie
        danych, potencjalnych odbiorc&oacute;w czy okres przechowywania.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 7.3.Prawo do sprostowania.Masz prawo do
        sprostowania przez nas nieprawidłowych dotyczących Ciebie Danych. Jeżeli
        przetwarzane przez nas dane nie są prawidłowe, poprawimy je bez zbędnej
        zwłoki i powiadomimy Cię o naniesieniu tych poprawek. Uwaga: (i) wiele
        danych możesz poprawić w ustawieniach (ii) nie mamy technicznej
        możliwości poprawienia wszystkich rodzaj&oacute;w danych w naszym
        Produkcie.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 7.4.Prawo do usunięcia.Masz prawo do usunięcia
        dotyczących Ciebie danych, kt&oacute;re przechowujemy. Jeżeli tak
        postanowisz, przejdź do ustawień swojego konta na naszej Witrynie
        internetowej i usuń je. Jeżeli nie jesteś w stanie tego zrobić,
        skontaktuj się z nami pod adresem email. Jako środek ostrożności wyślemy
        do Ciebie email z prośbą o potwierdzenie usunięcia danych. Po otrzymaniu
        potwierdzenia usuniemy Twoje dane. Pamiętaj, że po usunięciu konta na
        Twoim telefonie mogą wciąż być przechowywane dane.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 7.5.Prawo do ograniczenia przetwarzania.W
        następujących przypadkach masz prawo ograniczenia przetwarzania przez
        nas Twoich danych:&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;7.5.1.składasz zapytanie na
        podstawie punktu 7.4;&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.5.2.jesteś zdania, że
        przetwarzanie Twoich danych jest niezgodne z prawem, ale nie chcesz
        usunięcia danych;&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.5.3.nadal potrzebujesz
        danych do ustalenia lub dochodzenia roszczeń prawnych bądź obrony przed
        takimi roszczeniami; lub
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.5.4.zgłosiłeś/zgłosiłaś
        sprzeciw wobec przetwarzania&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 7.6.Prawo do przeniesienia danych.Masz prawo do (i)
        otrzymania kopii Twoich danych w uporządkowanym, powszechnie używanym i
        nadającym się do odczytu maszynowego formacie (ii) przesłania tych
        danych innemu administratorowi danych bez przeszk&oacute;d z naszej
        strony.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 7.7.Prawo do wniesienia sprzeciwu.Masz prawo do
        wniesienia w każdej chwili sprzeciwu wobec przetwarzania Danych,
        kt&oacute;rego podstawą prawną jest nasz uzasadniony interes. Dotyczy to
        także profilowania w oparciu o te postanowienia. Masz także prawo do
        wniesienia sprzeciwu wobec przetwarzania danych do cel&oacute;w
        marketingowych.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 7.8.Prawo do składania skarg.Masz prawo do złożenia
        skargi do lokalnego organu nadzorczego, jeżeli Twoim zdaniem
        przetwarzanie danych narusza obowiązujące prawo.&nbsp;
      </p>
      <p>8. R&oacute;żne.&nbsp;</p>
      <p>
        &nbsp; &nbsp; &nbsp; 8.1. Podstawy prawne. Prawo ochrony danych stanowi,
        że wolno nam zbierać i przetwarzać Twoje dane jedynie wtedy, gdy jest to
        prawnie uzasadnione. Uzasadnienie prawne przetwarzania danych wynika
        z:&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8.1.1.Twojej (wyraźnej)zgodyw
        przypadku, gdy udzieliłeś/udzieliłaś (wyraźnej) zgody na
        przetwarzanie;&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8.1.2.konieczności
        wykonaniaumowyz użytkownikiem, np. gdy dane są potrzebne do
        satysfakcjonującego użytkowania produktu; lub&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8.1.3.uzasadnionych
        interes&oacute;wDostawcy lub podmiot&oacute;w trzecich.&nbsp;
      </p>
      <p>
        Do naszych uzasadnionych interes&oacute;w należy ochrona Ciebie, Cardio
        Bike lub innych przed zagrożeniami dla bezpieczeństwa lub oszustwem,
        zgodność z wszystkimi stosownymi przepisami prawa, zarządzanie i
        usprawnianie naszej działalności biznesowej (np. obsługi klienta,
        sprawozdań), w tym ewentualne transakcje firmowe (np. fuzje i
        przejęcia), umożliwianie użytkownikom dzielenia się doświadczeniami w
        zakresie aktywności fizycznej i nawiązywanie kontakt&oacute;w na tej
        podstawie, a także wyrażanie opinii dotyczących aktywności fizycznej i
        zdrowia.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; 8.2.Środki bezpieczeństwa.Jesteśmy zaangażowani w
        ochronę Twoich Danych i wdrażanie odpowiednich zabezpieczeń technicznych
        i organizacyjnych, aby chronić je przed nieupoważnionym lub bezprawnym
        przetwarzaniem oraz przed przypadkową utratą, zniszczeniem lub
        uszkodzeniem. Te środki bezpieczeństwa są stale sprawdzane, aby
        odpowiadały najnowszym osiągnięciom technicznym. Dane osobowe
        przechowywane będą na naszych serwerach lub na platformach hostingowych
        firm trzecich (w szczeg&oacute;lności w środowiskach chmurowych
        Microsoft i/lub Google) zlokalizowanych na terenie UE.
      </p>
    </div>
  );
};

export default PrivatePolicyPage;
